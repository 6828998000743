import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';

import {finalize} from 'rxjs/operators';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

import {FormBaseModal} from '@shared/helpers/formBaseModal';
import {RateService} from '@shared/services/rate.service';
import {Router} from "@angular/router";
import {RatePair} from "@shared/models/ccyRates";
import {CurrencyService} from "@shared/services/currency.service";

@UntilDestroy()
@Component({
  selector: 'app-modal-exchange-rate-create',
  templateUrl: './modal-exchange-rate-create.component.html',
  styleUrls: ['./modal-exchange-rate-create.component.scss']
})
export class ModalExchangeRateCreateComponent extends FormBaseModal<any> implements OnInit {

  date: Date;
  previousRates: RatePair[];

  constructor(public bsModalRef: BsModalRef,
              public rateService: RateService,
              private _router: Router,
              private fb: FormBuilder,
              private currencyService: CurrencyService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    if (this.previousRates) {
      this.presetPreviousRates();
    }
  }

  onSubmit() {
    if (this.formData.valid) {
      this.loading = true;
      const rates = [];
      this.rateService.ccyPairsList.forEach(pair => {
        rates.push({
          original_currency: pair.original_currency,
          target_currency: pair.target_currency,
          rate: this.formData.value[`sell_${pair.original_currency}_${pair.target_currency}`],
        });
        rates.push({
          original_currency: pair.target_currency,
          target_currency: pair.original_currency,
          rate: this.formData.value[`buy_${pair.original_currency}_${pair.target_currency}`]
        });
      });
      this.rateService.createRates(rates)
        .pipe(
          untilDestroyed(this),
          finalize(() => this.loading = false)
        )
        .subscribe((response: any) => {
          this.onClose.next(response);
          this._router.navigate(['accounting/exchange-rates']);
          this.bsModalRef.hide();
        });
    } else {
      this.formData.markAllAsTouched();
    }
  }

  createForm() {
    this.formData = this.fb.group({});
    this.rateService.ccyPairsList.forEach(pair => {
      this.formData.addControl(`sell_${pair.original_currency}_${pair.target_currency}`, this.fb.control('', [Validators.required]));
      this.formData.addControl(`buy_${pair.original_currency}_${pair.target_currency}`, this.fb.control('', [Validators.required]));
    });
  }

  private presetPreviousRates() {
    this.rateService.ccyPairsList.forEach(pair => {
      const sellRate = this.currencyService.getRate(this.previousRates, pair.original_currency, pair.target_currency).rate;
      const buyRate = this.currencyService.getRate(this.previousRates, pair.target_currency, pair.original_currency).rate;
      this.formData.controls[`sell_${pair.original_currency}_${pair.target_currency}`]
        .setValue(sellRate);
      this.formData.controls[`buy_${pair.original_currency}_${pair.target_currency}`]
        .setValue(buyRate);
    });
  }
}
